import React from "react";
import { Fragment, useState } from "react";
import CustomInput from "../../components/CustomInput";
import { useNavigate, Link } from "react-router-dom";
import { Form, Button, Spinner, InputGroup } from "react-bootstrap";

import { Api } from "../../ApiData";
import { toast } from "react-toastify";
export const ForgotPassword = () => {

  const [inputData, setInputData] = useState({
    email: "",
  });

  // console.log("asdasds",0=="-0")
  // console.log("sfgsgfsdfjsgf",0===-0)
  // console.log("asdasds",0==-0)
  const navigate=useNavigate();

  const [error, setError] = useState(false);
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;


  const forgotPassword = async (e) => {
    e.preventDefault();
    const { email, pwd } = inputData;
    if (!email || !emailRegex.test(email) || !pwd) {
      setError(true);

    }

    let apiRes = await Api?.sendOtp(inputData);

    console.log(apiRes)
           
            if (apiRes?.status >= 200 && apiRes?.status <= 399) {
               // navigate("/project-report-list")
                toast.success(apiRes?.message)
                navigate("/verify-otp",{ state:{
                  email:inputData.email
                }
                })
            }
            if (apiRes?.status >= 400 && apiRes?.status <= 500) {
                toast.error(apiRes?.message);
                if (apiRes?.statusCode === 401) {
                    navigate("/");
                }
            }






}
  return (
    <Fragment>
      <div className="bg_main" id="login_page">
        <div className="log_bg d-block bg-white p-relative p-5 m-auto">
          <div className="login_head d-flex align-items-center">
            <h2 className="ms-3 fs-3">Forgot password</h2>
          </div>
          <hr />
          <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="fw-bold">EMAIL ADDRESS</Form.Label>
              <CustomInput
                type="text"
                placeholder="Enter your email address"
                value={inputData?.email}
                onChange={(e) =>
                  setInputData({ ...inputData, email: e.target.value })
                }
              />
              {error &&
                (!inputData?.email || !emailRegex.test(inputData?.email)) && (
                  <span className="text-danger">
                    {!inputData?.email
                      ? "Please enter you email address"
                      : !emailRegex.test(inputData?.email)
                      ? "Invalid email address"
                      : ""}
                  </span>
                )}
            </Form.Group>
            <div className="sign_btn mt-4">
              <Button type="submit" variant="primary" onClick={forgotPassword}>
                Send Otp
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}
