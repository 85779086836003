import { Fragment, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { Api } from "../../ApiData";

const Roles = () => {
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addRoleModal, setAddRoleModal] = useState(false);
  const [role, setRole] = useState("");
  const [error, setError] = useState(false);
  const [updateRoleModal, setUpdateRoleModal] = useState(false);
  const [updateRoleName, setUpdateRoleName] = useState("");
  const [updateRoleId, setUpdateRoleId] = useState("");

  const getRoles = async () => {
    const apiRes = await Api?.getRoles();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        setRoleList(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const handleActiveToggle = async (id) => {
    let payload = {
      id,
    };
    const apiRes = await Api?.updateRoleStatus(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      toast.success(apiRes?.message);
      getRoles();
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const handleAddRole = async () => {
    setAddRoleModal(true);
    if (!role) {
      setError(true);
    } else {
      setError(false);
      let payload = {
        role_name:role
      };
      let apiRes = await Api?.addRole(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        toast.success(apiRes?.message);
        setAddRoleModal(false);
        setRole("");
        getRoles();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  const handleUpdateRole = async () => {
    setUpdateRoleModal(true);
    if (!updateRoleName) {
      setError(true);
    } else {
      setError(false);
      let payload = {
        updateRoleId,
        updateRoleName,
      };
      let apiRes = await Api?.editRole(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        toast.success(apiRes?.message);
        setUpdateRoleModal(false);
        setUpdateRoleName("");
        getRoles();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <Fragment>
      <div className="departments_list_head right-nav-section">

        <div className="dual-sec">
            <div className="page-heading">
            <h3>Roles</h3>
            </div>
            <div className="add-btn">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setAddRoleModal(true)}
              >
                Add Role
              </button>
            </div>
        </div>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Role</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {roleList &&
              roleList?.map((ele, index) => {
                return (
                  <tr key={ele?.id}>
                    <td>{index + 1}.</td>
                    <td>{ele?.role_name}</td>
                    <td>
                      <ToggleButton
                        value={ele?.status === 1 ? true : false}
                        onToggle={() => handleActiveToggle(ele?.id)}
                        activeLabel=""
                        inactiveLabel=""
                      />
                    </td>
                    <td>
                        <div className="inner-actions">
                            <div className="edit-action" 
                                  onClick={() => {
                                    setUpdateRoleModal(true);
                                    setUpdateRoleName(ele?.role_name);
                                    setUpdateRoleId(ele?.id);
                                  }}
                            >
                                <i className="fas fa-edit"></i>
                            </div>

                            <div className="trash-action"
                              onClick={() => setDeleteModal(true)}
                            >
                                <i className="fas fa-trash"></i>
                            </div>
                        </div>
                      {/* <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          setUpdateRoleModal(true);
                          setUpdateRoleName(ele?.name);
                          setUpdateDptId(ele?.id);
                        }}
                      >
                        Update
                      </button>
                      <br />
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => setDeleteModal(true)}
                      >
                        Delete
                      </button> */}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>

      {/* Department Delete Modal */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Department Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Role?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => setDeleteModal(false)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Role Modal */}
      <Modal
        show={addRoleModal}
        onHide={() => {
          setAddRoleModal(false);
          setError(false);
          setRole("");
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the department name"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
              {error && !role && (
                <span className="text-danger">
                  Please enter the Role
                </span>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setAddRoleModal(false);
              setError(false);
              setRole("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleAddRole()}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update Department Modal */}
      <Modal
        show={updateRoleModal}
        onHide={() => {
          setUpdateRoleModal(false);
          setError(false);
          setUpdateRoleName("");
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a role"
                value={updateRoleName}
                onChange={(e) => setUpdateRoleName(e.target.value)}
              />
              {error && !updateRoleName && (
                <span className="text-danger">
                  Please enter the Role
                </span>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setUpdateRoleModal(false);
              setError(false);
              setUpdateRoleName("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleUpdateRole()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Roles;
