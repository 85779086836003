import React from "react";
import { Fragment, useState } from "react";
import CustomInput from "../../components/CustomInput";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Form, Button, Spinner, InputGroup } from "react-bootstrap";
import { Api } from "../../ApiData";
import { toast } from "react-toastify";
export const ConfirmPassword = () => {

  const [inputData, setInputData] = useState({
    password: "",
    confirmPassword:''
  });


  const location=  useLocation()

  console.log(location.state.email)

  const navigate=useNavigate();

  const [error, setError] = useState(false);
  

  const confirmPassword = async (e) => {

    e.preventDefault();

    if (!inputData.password ||inputData.password !== inputData.confirmPassword) {

       setError(true);
       return ;
      }
      

      let data={
        password:inputData.password,
        email:location.state.email

      }
      let apiRes = await Api?.confirmPassword(data);

    console.log(apiRes)
           
            if (apiRes?.status >= 200 && apiRes?.status <= 399) {
               // navigate("/project-report-list")
                toast.success(apiRes?.message)
                navigate("/",
                )
            }
            if (apiRes?.status >= 400 && apiRes?.status <= 500) {
                toast.error(apiRes?.message);
                if (apiRes?.statusCode === 401) {
                    navigate("/");
                }
            }



    


}
  return (
    <Fragment>
      <div className="bg_main" id="login_page">
        <div className="log_bg d-block bg-white p-relative p-5 m-auto">
          <div className="login_head d-flex align-items-center">
            <h2 className="ms-3 fs-3">Change Password</h2>
          </div>
          <hr />
          <Form>
          <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label className="fw-bold">New Password</Form.Label>
              <CustomInput
                type="password"
                placeholder="Enter your new password"
                value={inputData.password}
                onChange={(e) =>
                  setInputData({ ...inputData, password: e.target.value })
                }
              />
              {error &&
                (!inputData?.password ) && (
                  <span className="text-danger">
                    {!inputData?.password
                      ? "Password is required!"
                    
                      : ""}
                  </span>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupConfirmPassword">
              <Form.Label className="fw-bold">Confirm Password</Form.Label>
              <CustomInput
                type="password"
                placeholder="Confirm your new password"
                value={inputData.confirmPassword}
                onChange={(e) =>
                  setInputData({ ...inputData, confirmPassword: e.target.value })
                }
              />
             {error && (!inputData?.confirmPassword ?
                                  <span className="text-danger">Please enter confirm password.</span>
                                  : inputData?.password !== inputData?.confirmPassword && <span className="text-danger">Confirm password is not matched.</span>)
                                }
            </Form.Group>
            
            <div className="sign_btn mt-4">
              <Button type="submit" variant="primary" onClick={confirmPassword}>
                VerifyOtp
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}
