import { Fragment, useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';
import { toast } from "react-toastify";
import { Table, Modal, Button, Spinner } from "react-bootstrap";
import { Api } from "../../ApiData";
import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import ProfileMenImg from "../../assets/images/profilemen.png";

const EmployeesList = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const { toPDF, targetRef } = usePDF({ filename: 'employees.pdf' });

  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [emplId, setEmpId] = useState(null);
  const [payload, setPayload] = useState({
    start: 0,
    limit: 9,
    searchStr: ""
  })
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoader, setLoader] = useState(false);

  const getAllEmployees = async (start = 0, limit = 9, serachStr = "", is_view_more = false) => {
    setLoader(true)
    const apiRes = await Api?.getAllEmployeesList(start, limit, serachStr);
    setLoader(false)
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      let body = apiRes?.body.rows;
      setTotalRecords(apiRes?.body?.total_records)
      if (body.length && !serachStr) {
        if (allEmployeeList.length && is_view_more) {
          let old_records = allEmployeeList.concat(body);
          setAllEmployeeList(old_records);
        } else {
          setAllEmployeeList(apiRes?.body.rows);
        }
      } else {
        setAllEmployeeList(apiRes?.body.rows);
      }
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const handleActiveToggle = async (id, index) => {
    let payload = {
      id,
    };
    const apiRes = await Api?.updateEmpStatus(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      toast.success(apiRes?.message);
      getAllEmployees(payload?.start, payload?.limit, payload?.serachStr);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  /*********Open confirmation modal********/
  const openDltModal = (id) => {
    setEmpId(id);
    setDeleteModal(true);
  };

  /*********Delete Employee*******/
  const dltEmployee = async () => {
    if (emplId) {
      let payload = {
        id: emplId,
      };

      const apiRes = await Api?.deleteEmployee(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        setDeleteModal(false);
        setEmpId(null);
        toast.success(apiRes?.message);
        getAllEmployees(payload?.start, payload?.limit, payload?.serachStr);
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  const toEditPage = (id) => {
    navigate("/employees-list/edit-employee/" + id)
  }

  const toViewPage = (id) => {
    navigate("/employees-list/view-employee/" + id)
  }

  const viewMoreRecords = (start, limit) => {
    setPayload({ ...payload, start: start, limit: limit })
    getAllEmployees(start, limit, payload?.searchStr, true);
  }
  const serachEmp = (searchStr) => {
    setPayload({ ...payload, searchStr: searchStr })
    getAllEmployees(0, 9, searchStr);
  }
  useEffect(() => {
    getAllEmployees(payload?.start, payload?.limit, payload?.searchStr);
    getRolesData();
  }, []);

  const [userId, setUserId] = useState(null)
  const [roleId, setRoleId] = useState(null)
  const [roleModal, setRoleModal] = useState(false);
  const [rolesList, setRolesList] = useState([]);

  /*********Open Role modal********/
  const openPaymentModal = (id, role_id) => {
    setUserId(id);
    setRoleId(role_id);
    setRoleModal(true);
  };

  const getRolesData = async () => {
    const apiRes = await Api?.getActiveRoles();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setRolesList(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      toast.error("Something went wrong");
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const changeRole = (e) => {
    setRoleId(e.target.value);
  }

  const updateRole = async () => {
    let payload = {
      user_id: userId,
      role_id: roleId
    }
    let apiRes = await Api?.assignRole(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      toast.success(apiRes?.message);
      setRoleModal(false);
      getAllEmployees(payload?.start, payload?.limit, payload?.serachStr);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      toast.error("Something went wrong");
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  }

  console.log("eememe====",allEmployeeList)
  return (
    <Fragment>
      <div className="employee_list_head right-nav-section">
        <div className="dual-sec">
          <div className="page-heading">
            <h3>All Employees List</h3>
          </div>
          {/* <div className="add-btn">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => navigate("/employees-list/add-new-employee")}
            >
              Add New Employee
            </button>
          </div> */}
        </div>
        <div className="dual-section">
       
          <div className="add-btn">
            <div className="dual-exports">
              <div className="exprt-excel clickable">
                <DownloadTableExcel
                  filename="users table"
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <i class="fa-solid fa-file-excel 2px"></i>
                </DownloadTableExcel>
              </div>
            
              <div className="exprt-pdf clickable">
                <span onClick={() => toPDF()}>
                  <i class="fa-solid fa-file-pdf"></i>
                </span>
              </div>
            </div>
            <div className="search-emp">
              <input
                type="text"
                placeholder="Search for employee..."
                className="form-control"
                onChange={(e) => serachEmp(e.target.value)}
              />
            </div>
          </div>
        </div>
        <Table striped bordered hover size="sm" ref={targetRef}>
          <thead>
            <tr>
              <th>Sr.No.</th>
              {/* <th>Employee Id</th> */}
              <th>Name</th>
              <th>Photo</th>
              <th>Designation</th>
              <th>Department</th>
              {/* <th>Task Assigned</th> */}
              <th>Role</th>
              {/* <th>Note</th>
              <th>Status</th>
              <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {allEmployeeList && 
              allEmployeeList?.filter(emp => emp.status === 1) .sort((a, b) => a.first_name?.toLowerCase().localeCompare(b.first_name?.toLowerCase()))
              .map((emp, index) => (
                <tr>
                  <td className="clickable" onClick={() => toViewPage(emp?.id)}>{index + 1}</td>
                  {/* <td>{emp?.id}</td> */}
                  <td className="clickable" onClick={() => toViewPage(emp?.id)}>{emp?.first_name + " " + emp?.last_name}</td>
                  <td className="clickable" onClick={() => toViewPage(emp?.id)}>
                    <img src={emp?.full_image ? emp?.full_image : ProfileMenImg} height="100px" width="100px" />
                  </td>
                  <td className="clickable" onClick={() => toViewPage(emp?.id)}>{emp?.designation_name}</td>
                  <td className="clickable" onClick={() => toViewPage(emp?.id)}>{emp?.department_name}</td>
                  {/* <td className="clickable" onClick={() => toViewPage(emp?.id)}>N/A</td> */}
                  {/* <td><span className="badge clickable" onClick={(e) => openPaymentModal(emp?.id, emp?.role_id)}>{emp?.role_name + " "}<i className="fas fa-edit"></i></span></td> */}
                  <td>{emp?.role_name}</td>
                  {/* <td className="clickable" onClick={() => toViewPage(emp?.id)}>N/A</td> */}
                  {/* <td>
                    <ToggleButton
                      value={emp?.status === 1 ? true : false}
                      onToggle={() => handleActiveToggle(emp?.id, index)}
                      activeLabel=""
                      inactiveLabel=""
                    />
                  </td> */}
                  {/* <td>
                    <div className="inner-actions">
                      <div
                        className="edit-action"
                        onClick={(e) => {
                          toEditPage(emp?.id)
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </div>
                      <div
                        className="trash-action"
                        onClick={() => openDltModal(emp?.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </div>
                    </div>
                  </td> */}
                </tr>
              ))}
            {isLoader && <tr>
              <td colSpan="11" align="center">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </td>
            </tr>}
          </tbody>

        </Table>

        {totalRecords >= allEmployeeList?.length && allEmployeeList?.length>='9' && (
  <div className="view-more-btn" style={{ textAlign: "center" }}>
    <button type="button" className="btn btn-primary" onClick={(e) => viewMoreRecords(allEmployeeList.length, 9)}>
      View more
    </button>
  </div>
)}

        <Table style={{ display: "none" }} striped bordered hover size="sm" ref={tableRef}>
          <thead>
            <tr>
              <th>Sr.No.</th>
              {/* <th>Employee Id</th> */}
              <th>Name</th>
              <th>Photo</th>
              <th>Designation</th>
              <th>Department</th>
              <th>Task Assigned</th>
              <th>Role</th>
              <th>Note</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {allEmployeeList &&
              allEmployeeList.sort((a, b) => a.first_name?.toLowerCase().localeCompare(b.first_name?.toLowerCase())).map((emp, index) => (
                <tr>
                  <td>{index + 1}</td>
                  {/* <td>{emp?.id}</td> */}
                  <td>{emp?.first_name + " " + emp?.last_name}</td>
                  <td>
                    <img src={emp?.full_image ? emp?.full_image : ProfileMenImg} height="100px" width="100px" />
                  </td>
                  <td>{emp?.designation_name}</td>
                  <td>{emp?.department_name}</td>
                  <td>N/A</td>
                  <td>{emp?.role_name}</td>
                  <td>N/A</td>
                  <td>{emp?.status === 1 ? "Active" : "Inactive"}</td>
                </tr>
              ))}
            <tr>
              <td colSpan="11" align="center">
                {isLoader && <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />}
              </td>
            </tr>
          </tbody>

        </Table>



        {/* Change Role Modal */}
        <Modal
          show={roleModal}
          onHide={() => setRoleModal(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label"
                >
                  Select Role <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                </label>
                <select
                  className="form-select no-space"
                  aria-label="Default select example"
                  onChange={(e) => changeRole(e)}
                >
                  <option disabled hidden>Select Role</option>
                  {rolesList &&
                    rolesList.map((role, index) => (
                      <option key={index} value={role?.id} selected={role?.id == roleId}>
                        {role?.role_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => updateRole()} disabled={isLoader}>
              Update {isLoader && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Employee Delete Modal */}
        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this Employee?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteModal(false)}>
              No
            </Button>
            <Button variant="primary" onClick={() => dltEmployee()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    </Fragment >
  );
};

export default EmployeesList;
