import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Api } from "../../ApiData";
import { toast } from "react-toastify";
import UserImage from "../../assets/images/user.png";
const EmployeeDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [empDetails, setEmpDetails] = useState();
  const getEmployeeDetailsById = async () => {
    let id = params?.id;
    let apiRes = await Api?.getEmployeeDetailsById(id);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setEmpDetails(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      toast.error(apiRes?.message);
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  console.log("emplyee details,", empDetails);
  useEffect(() => {
    getEmployeeDetailsById();
  }, []);
  return (
    <Fragment>
      <div className="employee-profile right-nav-section" id="employee-pro">
        <Link className="back-to" to="/employees-list">
          Back
        </Link>
        <div className="employee-details">
          <img
            className="profile-pic"
            src={
              empDetails?.full_image === null
                ? UserImage
                : empDetails?.full_image
            }
            alt="Profile Picture"
          />
          <div className="employee-info">
            <h2>
              {empDetails?.first_name} {empDetails?.last_name}
            </h2>
            <p>{empDetails?.email}</p>
            <strong>{empDetails?.dest_name}</strong>, specializing in{" "}
            <strong>{empDetails?.dept_name}</strong> development.
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th>Table Header 1</th>
              <th>Table Header 2</th>
              <th>Table Header 3</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Row 1, Cell 1</td>
              <td>Row 1, Cell 2</td>
              <td>Row 1, Cell 3</td>
            </tr>
            <tr>
              <td>Row 2, Cell 1</td>
              <td>Row 2, Cell 2</td>
              <td>Row 2, Cell 3</td>
            </tr>
            <tr>
              <td>Row 3, Cell 1</td>
              <td>Row 3, Cell 2</td>
              <td>Row 3, Cell 3</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default EmployeeDetails;
