import Auth from "../auth/Auth";

const URL = process.env.REACT_APP_BASE_URL;

const userLogin = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: payload.email,
      password: payload.pwd,
    }),
  };
  await fetch(`${URL}/userLogin`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const getUserDetails = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getUserDetails`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const updateUserDetails = async (payload) => {
  let response;
  const formData = new FormData();
  formData.append("first_name", payload?.f_name);
  formData.append("last_name", payload?.l_name);
  formData.append("profile_image", payload?.profileImage);
  const requestKey = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: formData,
  };
  await fetch(`${URL}/updateAdminProfile`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const getAllDepartmentsList = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getAllDepartments`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const getAllDesignationsList = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getAllDesignations`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const updateDptStatus = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload.id,
    }),
  };
  await fetch(`${URL}/updateDeptStatus`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const updateDstStatus = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload.id,
    }),
  };
  await fetch(`${URL}/updateDestStatus`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const addDpt = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      department_name: payload.dptName,
    }),
  };
  await fetch(`${URL}/addDepartment`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const updateDpt = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload?.updateDptId,
      department_name: payload.updateDptName,
    }),
  };
  await fetch(`${URL}/editDepartment`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const addDest = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      designation_name: payload.destName,
    }),
  };
  await fetch(`${URL}/addDesignation`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const updateDest = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload?.updateDestId,
      designation_name: payload.updateDestName,
    }),
  };
  await fetch(`${URL}/editDesignation`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/************Add new upwork id********/
const addUpworkId = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      upwork_id: payload.upworkId,
    }),
  };
  await fetch(`${URL}/addUpwork`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/************Update upwork id********/
const updateUpworkId = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload?.updateUpwork_id,
      upwork_id: payload.updateUpworkId,
    }),
  };
  await fetch(`${URL}/editUpwork`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Get all upworkd ids*****/
const getAllUpworkIds = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getAllUpworkIds`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Get Active upworkd ids*****/
const getActiveUpworkIds = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getActiveUpworkIds`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/*************Upwork id active/Inactive************/
const updateUpworkIdStatus = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload.id,
    }),
  };
  await fetch(`${URL}/updateUpworkStatus`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/***********Change Password*****/
const changePassword = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      current_password: payload?.current_password,
      new_password:payload?.new_password
    }),
  };
  await fetch(`${URL}/changePassword`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Get all Employees list ids*****/
const getAllEmployeesList = async (start,limit,searchStr) => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getAllEmployeesList/${start}/${limit}/${searchStr}`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/*************Employee active/Inactive************/
const updateEmpStatus = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload.id,
    }),
  };
  await fetch(`${URL}/updateEmpStatus`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/***********Delete employee********/
const deleteEmployee=async(payload)=>{
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload.id,
    }),
  };
  await fetch(`${URL}/deleteEmployee`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}

const getActiveDesignations = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getActiveDesignations`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const getActiveDepartments = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getActiveDepartments`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const getActiveTeamLeads = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getActiveTeamLeadList`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

const getActiveRoles = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getActiveRoles`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Register employee*******/
const registerEmployee=async(payload)=>{
  let response;
  const formData=new FormData();
  formData.append("first_name",payload?.f_name);
  formData.append("last_name",payload?.l_name);
  formData.append("email",payload?.email);
  formData.append("department_id",payload?.dpt_id);
  formData.append("designation_id",payload?.dst_id);
  formData.append("skype_id",payload?.skype_id);
  formData.append("team_lead_id",payload?.team_lead_id);
  formData.append("role_id",payload?.role_id);
  formData.append("password",payload?.password);
  formData.append("profile_pic",payload?.profile_pic);
  console.log("payload",payload);
  const requestKey = {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body:formData
  };

  await fetch(`${URL}/registerEmployee`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}


/**********Get employee details by id*******/
const getEmpDetails=async(id)=>{
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };

  await fetch(`${URL}/getEmpDetails/${id}`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}

/**********Update employee*******/
const updateEmpDetails=async(payload)=>{
  let response;
  const formData=new FormData();
  formData.append("first_name",payload?.f_name);
  formData.append("last_name",payload?.l_name);
  formData.append("email",payload?.email);
  formData.append("department_id",payload?.dpt_id);
  formData.append("designation_id",payload?.dst_id);
  formData.append("skype_id",payload?.skype_id);
  formData.append("team_lead_id",payload?.team_lead_id);
  formData.append("role_id",payload?.role_id);
  formData.append("user_id",payload?.user_id);
  // formData.append("password",payload?.password);
  formData.append("profile_pic",payload?.profile_pic);
  console.log("payload",payload);
  const requestKey = {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body:formData
  };

  await fetch(`${URL}/updateEmpDetails`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}

/**********Get Dashboard Content*******/
const getDashboardContent=async()=>{
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };

  await fetch(`${URL}/getDashboardContent`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}

/**********Get All Projects Content*******/
const getAllProjects=async()=>{
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };

  await fetch(`${URL}/getAllProjects`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}

/**********Get All Active Projects Content*******/
const getActiveEmployeesList=async()=>{
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };

  await fetch(`${URL}/getActiveEmployeesList`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}

/**********Add Project*******/
const addProject=async(payload)=>{
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify(payload)
  };

  await fetch(`${URL}/addProject`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
  });

  return response;
}

const updateProjectStatus=async(payload)=>{
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload.id,
    }),
  };
  await fetch(`${URL}/updateProjectStatus`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}

/***********Delete Project********/
const deleteProject=async(payload)=>{
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload.id,
    }),
  };
  await fetch(`${URL}/deleteProject`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}

/**********Get Project details by id*******/
const getProjectById=async(id)=>{
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };

  await fetch(`${URL}/getProjectById/${id}`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}


/**********Update Project*******/
const editProject=async(payload)=>{
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify(payload)
  };

  await fetch(`${URL}/editProject`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
  });

  return response;
}


/**********Get Project Status*******/
const getActiveProjectStatus=async(id)=>{
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };

  await fetch(`${URL}/getActiveProjectStatus`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}

/**********Update project payment type *******/
const updateProjectPayType=async(payload)=>{
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify(payload)
  };

  await fetch(`${URL}/updateProjectPayType`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
  });

  return response;
}

/**********Update project status title *******/
const updateProjectStatusTitle=async(payload)=>{
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify(payload)
  };

  await fetch(`${URL}/updateProjectStatusTitle`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
  });

  return response;
}


/**********Get Employee Details By Id*******/
const getEmployeeDetailsById=async(id)=>{
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };

  await fetch(`${URL}/getEmployeeDetailsById/${id}`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}

/**********Get top project list*******/
const getTopActiveProjects=async(id)=>{
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };

  await fetch(`${URL}/getTopActiveProjects`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}


/***********Get all roles*********/
const getRoles = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };


  await fetch(`${URL}/getRoles`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Add role******/
const addRole = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify(payload),
  };
  await fetch(`${URL}/addRole`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Update role******/
const editRole = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload?.updateRoleId,
      role_name: payload?.updateRoleName,
    }),
  };
  await fetch(`${URL}/editRole`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Update Role's Status*********/
const updateRoleStatus = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      id: payload.id,
    }),
  };
  await fetch(`${URL}/updateRoleStatus`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/***********Get all roles*********/
const getPermissions = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getPermissions`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/***********Get Roles and permissions using id*********/
const getRoleAndPermissionById = async (rold_id) => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getRoleAndPermissionById/${rold_id}`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Assign permission*********/
const assignPermissionToRole = async (role_id,permission_id) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({
      role_id: role_id,
      permission_id: permission_id
    }),
  };
  await fetch(`${URL}/assignPermissionToRole`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};


/**********Assign Role*********/
const assignRole = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify(payload),
  };
  await fetch(`${URL}/assignRole`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};


/***********Get Roles and permissions using token*********/
const getRoleAndPermission = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getRoleAndPermission`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};


/**********Get Active task status*****/
const getActiveTaskStatus = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getActiveTaskStatus`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};


/**********Get My Task List*****/
const getMyTaskList = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getMyTaskList`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Get task duration list*****/
const getActiveTaskDuration = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getActiveTaskDuration`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Get Active Project Roles*****/
const getActiveProjectRoles = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getActiveProjectRoles`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Get Active Projects*****/
const getActiveProjects = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getActiveProjects`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Add my task*********/
const addMyNewTask = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify(payload),
  };
  await fetch(`${URL}/addMyNewTask`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/**********Get All Task List*****/
const getAllTaskList = async () => {
  let response;
  const requestKey = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  };
  await fetch(`${URL}/getAllTaskList`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};

/*********Assign task***/
const assignTask=async(user_id,task_id)=>{
  let payload={
    user_id:user_id,
    task_id:task_id
  }
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify(payload),
  };
  await fetch(`${URL}/assignTask`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}

/*********Assign task***/
const updateTaskStatus=async(task_status_id,task_id)=>{
  let payload={
    task_status_id:task_status_id,
    task_id:task_id
  }
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify(payload),
  };
  await fetch(`${URL}/updateTaskStatus`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
}


const getProjectsByUser = async (payload) => {

  let response;
    const params = new URLSearchParams();
    params.append("id", payload.id);

    const url = `${URL}/getProjectsByUser?${params.toString()}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization":  `Bearer ${Auth.token()}`
    },
    redirect: "follow",
  };
  
  await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};



// forgot password

const sendOtp = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
     
    },
    body: JSON.stringify(payload),
  };
  await fetch(`${URL}/forgotPassword`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};



const verifyOTP = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
     
    },
    body: JSON.stringify(payload),
  };
  await fetch(`${URL}/verifyOTP`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};




const confirmPassword = async (payload) => {
  let response;
  const requestKey = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
     
    },
    body: JSON.stringify(payload),
  };
  await fetch(`${URL}/resetPassword`, requestKey)
    .then((res) => res.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = error;
    });

  return response;
};



let Result = {
  userLogin,
  getUserDetails,
  updateUserDetails,
  getAllDepartmentsList,
  getAllDesignationsList,
  updateDptStatus,
  updateDstStatus,
  addDpt,
  updateDpt,
  addDest,
  updateDest,
  getAllUpworkIds,
  getActiveUpworkIds,
  addUpworkId,
  updateUpworkId,
  updateUpworkIdStatus,
  changePassword,  
  getAllEmployeesList,
  updateEmpStatus,
  deleteEmployee,
  getActiveDesignations,
  getActiveDepartments,
  getActiveTeamLeads,
  getActiveRoles,
  registerEmployee,
  getEmpDetails,
  updateEmpDetails,
  getDashboardContent,
  getAllProjects,
  getActiveEmployeesList,
  addProject,
  updateProjectStatus,
  deleteProject,
  getProjectById,
  editProject,
  getActiveProjectStatus,
  updateProjectPayType,
  updateProjectStatusTitle,
  getEmployeeDetailsById,
  getTopActiveProjects,
  getRoles,
  addRole,
  editRole,
  updateRoleStatus,
  getPermissions,
  getRoleAndPermissionById,
  assignPermissionToRole,
  assignRole,
  getRoleAndPermission,
  getActiveTaskStatus,
  getMyTaskList,
  getActiveTaskDuration,
  getActiveProjectRoles,
  getActiveProjects,
  addMyNewTask,
  getAllTaskList,
  assignTask,
  updateTaskStatus,
  getProjectsByUser,
  sendOtp,
  verifyOTP,
  confirmPassword
};

export default Result;
