import { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [editProfileData, setEditProfileData] = useState("");
  const [initialUserData, setInitialUserData] = useState("");
  
  return (
    <AppContext.Provider value={{ editProfileData, setEditProfileData, initialUserData, setInitialUserData}}>
      {children}
    </AppContext.Provider>
  );
};
