import { Fragment, useContext, useState } from "react";

import { useNavigate, Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";

import { AppContext } from "../../context/AppContext";
import Auth from "../../auth/Auth";

const Header = () => {
  const navigate = useNavigate();
  const { initialUserData } = useContext(AppContext);

  const [logout,setLogoutModal]=useState(false);
  const handleLogout = () => {
    Auth?.logout();
    navigate("/");
  };

  const openLogoutModal=()=>{
    setLogoutModal(true);
  }

  return (
    <Fragment>
      <div className="myheader">
        <nav className="sb-topnav navbar navbar-expand">
          <Link className="navbar-brand ps-3" to="/dashboard">
            <h4>Employee Panel</h4>
          </Link>
          <button
            className="btn btn-link btn-sm order-1 order-lg-0 me-lg-0"
            id="sidebarToggle"
            href="#!"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 16H3C2.73478 16 2.48043 16.1054 2.29289 16.2929C2.10536 16.4804 2 16.7348 2 17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H13C13.2652 18 13.5196 17.8946 13.7071 17.7071C13.8946 17.5196 14 17.2652 14 17C14 16.7348 13.8946 16.4804 13.7071 16.2929C13.5196 16.1054 13.2652 16 13 16ZM3 8H21C21.2652 8 21.5196 7.89464 21.7071 7.70711C21.8946 7.51957 22 7.26522 22 7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7C2 7.26522 2.10536 7.51957 2.29289 7.70711C2.48043 7.89464 2.73478 8 3 8ZM21 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z"
                fill="black"
              />
            </svg>
          </button>
          <div className="ms-auto head_pic">
          <Dropdown className="head_profile">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              
                <span className="head_prf_name">
                  {initialUserData?.first_name
                    ? initialUserData?.first_name
                    : Auth?.getAdminData()?.first_name}
                </span>
                <img
                  src={
                    initialUserData?.full_image
                      ? initialUserData?.full_image
                      : Auth?.getAdminData()?.full_image
                  }
                  alt="admin_img"
                />
            
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate("/my-profile")}>
                My Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => openLogoutModal()}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </div>
        </nav>
      </div>

        {/* Logout Confirmation Modal */}
        <Modal
          show={logout}
          onHide={() => setLogoutModal(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Logout Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you realy want to logout your account?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setLogoutModal(false)}>
              No
            </Button>
            <Button variant="primary" onClick={() => handleLogout()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

    </Fragment>
  );
};

export default Header;
