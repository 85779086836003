import { Fragment, useEffect, useState,useRef } from "react";
import { toast } from "react-toastify";
import { Table, Modal, Button, Spinner } from "react-bootstrap";
import { Api } from "../../ApiData";
import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import ProfileMenImg from "../../assets/images/profilemen.png";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';

import Auth from "../../auth/Auth";

const ProjectReportList = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const { toPDF, targetRef } = usePDF({ filename: 'project-report.pdf' });

  console.log("=====>>>>", Auth.getAdminData());

  const [projects, setProjects] = useState([]);
  const paymentType = ["Fixed", "Billing", "Inhouse", "R&D"];

  const addProject = async () => {
    let apiRes = await addProject();
  }

  const getProjectList = async () => {
    let apiRes = await Api?.getAllProjects();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setProjects(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  }

  //console.log("project list",projects)
  const handleActiveToggle = async (id) => {
    let payload = {
      id,
    };
    const apiRes = await Api?.updateProjectStatus(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      toast.success(apiRes?.message);
      getProjectList();
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [editStatusModal, setEditStatusModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [selPaymentType, setSelPaymentType] = useState(null);
  const [selProjectStatus, setSelProjectStatus] = useState(null);
  const [error, setError] = useState(false);
  const [projectStatusAll, setProjectStatusList] = useState([])
  const [loader, setLoader] = useState(false);

  const[userId,setUserId]=useState()

  const[userProjects,setUserProjects]=useState([])

  const getActiveProjectStatus = async () => {
    let apiRes = await Api?.getActiveProjectStatus();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setProjectStatusList(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      toast.error(apiRes?.message);
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  }

  /*********Open Project status modal********/
  const openProjectModal = (id, project_status) => {
    setProjectId(id);
    setEditStatusModal(true);
    setSelProjectStatus(project_status)
  };


  /*********Open Payment type modal********/
  const openPaymentModal = (id, payment_type) => {
    setProjectId(id);
    setPaymentModal(true);
    setSelPaymentType(payment_type)
  };

  /*********Open confirmation modal********/
  const openDltModal = (id) => {
    setProjectId(id);
    setDeleteModal(true);
  };

  /*********Delete Project*******/
  const dltProject = async () => {
    if (projectId) {
      let payload = {
        id: projectId,
      };

      const apiRes = await Api?.deleteProject(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        setDeleteModal(false);
        setProjectId(null);
        toast.success(apiRes?.message);
        getProjectList();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  /*********Payment Type Project*******/
  const PaymentTypeProject = async () => {
    if (projectId && selPaymentType) {
      let payload = {
        id: projectId,
        payment_type: selPaymentType
      };
      setLoader(true)

      const apiRes = await Api?.updateProjectPayType(payload);
      setLoader(false)

      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        setPaymentModal(false);
        setProjectId(null);
        toast.success(apiRes?.message);
        getProjectList();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  /*********Project Status*******/
  const updateProjectStatusTitle = async () => {
    if (projectId && selProjectStatus) {
      let payload = {
        id: projectId,
        project_status: selProjectStatus
      };
      setLoader(true)

      const apiRes = await Api?.updateProjectStatusTitle(payload);
      setLoader(false)
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        setEditStatusModal(false);
        setProjectId(null);
        toast.success(apiRes?.message);
        getProjectList();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  const toEditPage = (project_id) => {
    navigate(`/project-report-list/edit-project/${project_id}`)
  }

  const changePaymentType = (e) => {
    setSelPaymentType(e.target.value)
  }

  const changeProjectStatus = (e) => {
    setSelProjectStatus(e.target.value)
  }


// get the data from local storage 
  let name=localStorage.getItem("data")

  let data=JSON.parse(name)
  console.log("loca storage",data.id)

  const getAllProjectByUser = async () => {
    console.log("test")

    if (data.id) {

      console.log(userId)
      let payload = {
        id: data.id,
      };
      const apiRes = await Api?.getProjectsByUser(payload);

      // console.log(" testvdfv",apiRes)

      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        setUserProjects(apiRes?.body);
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  



  useEffect(() => {
    getAllProjectByUser();
    getProjectList();
    getActiveProjectStatus();

   
  }, [])

  console.log("project repost list",projects)
  return (
    <Fragment>
      <div className="employee_list_head right-nav-section">
        <div className="dual-sec">
          <div className="page-heading">
            <h3>Project Report List</h3>
          </div>
          {/* <div className="add-btn">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => navigate("/project-report-list/add-project")}
            >
              Add New Project
            </button>
          </div> */}
        </div>

        <div className="dual-exports">
          <div className="exprt-excel clickable">
            <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <i class="fa-solid fa-file-excel 2px"></i>
            </DownloadTableExcel>
          </div>
          <div className="exprt-pdf clickable">
            <span onClick={() => toPDF()}>
              <i class="fa-solid fa-file-pdf"></i>
            </span>
          </div>
        </div>

        <Table striped bordered hover size="sm" ref={targetRef}>
          <thead className="thead-light">
            <tr>
              <th>Sr.No.</th>
              <th>Project Name</th>
              <th>Client Name</th>
              <th>Upwork Id</th>
              <th>Handled By</th>
              <th>Team Lead</th>
              {/* <th>Payment Type</th> */}
              <th>Project Status</th>
              {/* <th>Status</th> */}
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {userProjects &&
              userProjects.map((project, index) => (
                <tr>
                  <td>{++index}</td>
                  <td>{project?.project_name}</td>
                  <td>{project?.client_name}</td>
                  <td>{project?.upwork_id} {project.upwork_id_status ? <i className="fas fa-check-circle fa-1px text-success" title="This upwork id is Active"></i> : <i className="fas fa-times-circle fa-1x text-danger" title="This upwork id has been deactivated."></i>}</td>
                  <td>{project?.emp_first_name} {project?.emp_last_name}</td>
                  <td>{project?.lead_first_name} {project?.lead_last_name}</td>
                  {/* <td><span className="badge clickable" onClick={(e) => openPaymentModal(project?.id, project?.payment_type)}>{project?.payment_type + " "}<i className="fas fa-edit"></i></span></td> */}
                  {/* <td><span className="badge clickable" onClick={(e) => openProjectModal(project?.id, project?.project_status)}>{project?.project_status_title + " "}<i className="fas fa-edit"></i></span></td> */}
                  <td>{project?.project_status_title}</td>
                  {/* <td>
                    <ToggleButton
                      value={project?.status === 1 ? true : false}
                      onToggle={() => handleActiveToggle(project?.id)}
                      activeLabel=""
                      inactiveLabel=""
                    />
                  </td> */}
                  {/* <td>
                    <div className="inner-actions">
                      <div
                        className="edit-action"
                        onClick={(e) => {
                          toEditPage(project?.id)
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </div>

                      <div
                        className="trash-action"
                        onClick={() => openDltModal(project?.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </div>
                    </div>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </Table>
        <Table striped bordered hover size="sm" ref={tableRef} style={{display:"none"}}>
          <thead className="thead-light">
            <tr>
              <th>Sr.No.</th>
              <th>Project Name</th>
              <th>Client Name</th>
              <th>Upwork Id</th>
              <th>Handled By</th>
              <th>Team Lead</th>
              <th>Payment Type</th>
              <th>Project Status</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {projects &&
              projects.map((project, index) => (
                <tr>
                  <td>{++index}</td>
                  <td>{project?.project_name}</td>
                  <td>{project?.client_name}</td>
                  <td>{project?.upwork_id} {project.upwork_id_status ? <i className="fas fa-check-circle fa-1px text-success" title="This upwork id is Active"></i> : <i className="fas fa-times-circle fa-1x text-danger" title="This upwork id has been deactivated."></i>}</td>
                  <td>{project?.emp_first_name} {project?.emp_last_name}</td>
                  <td>{project?.lead_first_name} {project?.lead_last_name}</td>
                  <td><span className="badge clickable" onClick={(e) => openPaymentModal(project?.id, project?.payment_type)}>{project?.payment_type + " "}<i className="fas fa-edit"></i></span></td>
                  <td><span className="badge clickable" onClick={(e) => openProjectModal(project?.id, project?.project_status)}>{project?.project_status_title + " "}<i className="fas fa-edit"></i></span></td>
                  <td>
                    {project?.status === 1 ? "Active" : "Inactive"}
                  </td>
                  <td>
                    <div className="inner-actions">
                      <div
                        className="edit-action"
                        onClick={(e) => {
                          toEditPage(project?.id)
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </div>

                      <div
                        className="trash-action"
                        onClick={() => openDltModal(project?.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {/* Project Delete Modal */}
        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this Project?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteModal(false)}>
              No
            </Button>
            <Button variant="primary" onClick={() => dltProject()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Change Project status Modal */}
        <Modal
          show={editStatusModal}
          onHide={() => setEditStatusModal(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Project Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label"
                >
                  Select Project Status <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                </label>
                <select
                  className="form-select no-space"
                  aria-label="Default select example"
                  onChange={(e) => changeProjectStatus(e)}
                // defaultValue="Select Payment Type"
                >
                  <option disabled hidden>Select Project Status</option>
                  {projectStatusAll &&
                    projectStatusAll.map((status, index) => (
                      <option key={index} value={status?.id} selected={status?.id == selProjectStatus}>
                        {status?.project_status_title}
                      </option>
                    ))}
                </select>
                {error && !selProjectStatus && (
                  <span className="text-danger">
                    Please select project status
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => updateProjectStatusTitle()} disabled={loader}>
              Update {loader && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Change Project status Modal */}
        <Modal
          show={paymentModal}
          onHide={() => setPaymentModal(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Payment Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label"
                >
                  Select Payment Type <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                </label>
                <select
                  className="form-select no-space"
                  aria-label="Default select example"
                  onChange={(e) => changePaymentType(e)}
                // defaultValue="Select Payment Type"
                >
                  <option disabled hidden>Select Payment Type</option>
                  {paymentType &&
                    paymentType.map((type) => (
                      <option key={type} value={type} selected={type == selPaymentType}>
                        {type}
                      </option>
                    ))}
                </select>
                {error && !selPaymentType && (
                  <span className="text-danger">
                    Please select payment type
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => PaymentTypeProject()} disabled={loader}>
              Update {loader && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </Fragment>
  );
};

export default ProjectReportList;
