import React from "react";
import { Fragment, useState } from "react";
import CustomInput from "../../components/CustomInput";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Form, Button, Spinner, InputGroup } from "react-bootstrap";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Api } from "../../ApiData";
import { toast } from "react-toastify";
export const VerifyOtp = () => {

  // const [inputData, setInputData] = useState({
   
  //   otp:''
  // });

  const [otp, setOTP] = useState("");
 // const [OTP, setOTP] = useState("");
const location=  useLocation()

console.log(location.state)

  const navigate=useNavigate();

  const [error, setError] = useState(false);
 

  const otpRegex = /^\d{4}$/;

  const VerifyOtp = async (e) => {
    e.preventDefault();
    //const {  otp } = inputData;
    

      if (!otp || !otpRegex.test(otp)) {
      setError(true);
      return;
    }

    let data={

      otp,
      email:location.state.email
    }

    let apiRes = await Api?.verifyOTP(data);

    console.log(apiRes)
           
            if (apiRes?.status >= 200 && apiRes?.status <= 399) {
               // navigate("/project-report-list")
                toast.success(apiRes?.message)
                navigate("/confirm-password",{
                  state:{

                    email:location.state.email
                  }
                })

            }
            if (apiRes?.status >= 400 && apiRes?.status <= 500) {
                toast.error(apiRes?.message);
                if (apiRes?.statusCode === 401) {
                    navigate("/");
                }
            }


}
  return (
    <Fragment>
      <div className="bg_main" id="login_page">
        <div className="log_bg d-block bg-white p-relative p-5 m-auto">
          <div className="login_head d-flex align-items-center">
            <h2 className="ms-3 fs-3">VerifyOtp</h2>
          </div>
          <hr />
          <Form>
            
       
            {/* <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="fw-bold">OTP</Form.Label>
              <CustomInput
                type="text"
                placeholder="Enter your otp here"
                value={inputData?.otp}
                onChange={(e) =>
                  setInputData({ ...inputData, otp: e.target.value })
                }
              />
              {error &&
                (!inputData?.otp || !otpRegex.test(inputData?.otp)) && (
                  <span className="text-danger">
                    {!inputData?.otp
                      ? "Please enter your otp"
                      : !otpRegex.test(inputData?.otp)
                      ? "Please enter your  4 digit otp"
                      : ""}
                  </span>
                )}
            </Form.Group> */}
            <div className="d-flex justify-content-center">
            <OTPInput
              value={otp}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
              secure
            />
            </div>
            
            {error && (!otp || !otpRegex.test(otp)) && (
              <span className="text-danger d-flex justify-content-center pt-2">
                {!otp
                  ? "Please enter your OTP"
                  : !otpRegex.test(otp)
                  ? "Please enter a 4-digit OTP"
                  : ""}
              </span>
            )}

            <div className="sign_btn mt-4">
              <Button type="submit" variant="primary" onClick={VerifyOtp}>
                Verify
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}
