import { Fragment, useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { Api } from "../../ApiData";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';

const DepartmentsList = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const { toPDF, targetRef } = usePDF({ filename: 'project-report.pdf' });

  const [departmentsList, setDepartmentsList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addDptModal, setAddDptModal] = useState(false);
  const [dptName, setDptName] = useState("");
  const [error, setError] = useState(false);
  const [updateDptModal, setUpdateDptModal] = useState(false);
  const [updateDptName, setUpdateDptName] = useState("");
  const [updateDptId, setUpdateDptId] = useState("");

  const allDepartmentsList = async () => {
    const apiRes = await Api?.getAllDepartmentsList();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setDepartmentsList(apiRes?.body);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const handleActiveToggle = async (id) => {
    let payload = {
      id,
    };
    const apiRes = await Api?.updateDptStatus(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      toast.success(apiRes?.message);
      allDepartmentsList();
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const handleAddDpt = async () => {
    setAddDptModal(true);
    if (!dptName) {
      setError(true);
    } else {
      setError(false);
      let payload = {
        dptName,
      };
      let apiRes = await Api?.addDpt(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        toast.success(apiRes?.message);
        setAddDptModal(false);
        setDptName("");
        allDepartmentsList();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  const handleUpdateDpt = async () => {
    setUpdateDptModal(true);
    if (!updateDptName) {
      setError(true);
    } else {
      setError(false);
      let payload = {
        updateDptId,
        updateDptName,
      };
      let apiRes = await Api?.updateDpt(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        toast.success(apiRes?.message);
        setUpdateDptModal(false);
        setUpdateDptName("");
        allDepartmentsList();
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
          navigate("/");
        }
      }
    }
  };

  useEffect(() => {
    allDepartmentsList();
  }, []);

  return (
    <Fragment>
      <div className="departments_list_head right-nav-section">
        {/* <div>
          <h3>Departments List</h3>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setAddDptModal(true)}
          >
            Add Department
          </button>
        </div> */}

        <div className="dual-sec">
          <div className="page-heading">
            <h3>Departments List</h3>
          </div>
          {/* <div className="add-btn">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setAddDptModal(true)}
            >
              Add Department
            </button>
          </div> */}
        </div>
        <div className="dual-exports">
          <div className="exprt-excel clickable">
            <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <i class="fa-solid fa-file-excel 2px"></i>
            </DownloadTableExcel>
          </div>
          <div className="exprt-pdf clickable">
            <span onClick={() => toPDF()}>
              <i class="fa-solid fa-file-pdf"></i>
            </span>
          </div>
        </div>
        <Table striped bordered hover size="sm" ref={targetRef}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Department Name</th>
              {/* <th>Status</th>
              <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {departmentsList &&
              departmentsList?.map((ele, index) => {
                return (
                  <tr key={ele?.id}>
                    <td>{index + 1}.</td>
                    <td>{ele?.name}</td>
                    {/* <td>
                      <ToggleButton
                        value={ele?.status === 1 ? true : false}
                        onToggle={() => handleActiveToggle(ele?.id)}
                        activeLabel=""
                        inactiveLabel=""
                      />
                    </td> */}
                    <td>
                      {/* <div className="inner-actions">
                        <div className="edit-action"
                          onClick={() => {
                            setUpdateDptModal(true);
                            setUpdateDptName(ele?.name);
                            setUpdateDptId(ele?.id);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </div>

                        <div className="trash-action"
                          onClick={() => setDeleteModal(true)}
                        >
                          <i className="fas fa-trash"></i>
                        </div>
                      </div> */}
                      {/* <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                          setUpdateDptModal(true);
                          setUpdateDptName(ele?.name);
                          setUpdateDptId(ele?.id);
                        }}
                      >
                        Update
                      </button>
                      <br />
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => setDeleteModal(true)}
                      >
                        Delete
                      </button> */}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Table striped bordered hover size="sm" ref={tableRef} style={{ display: "none" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Department Name</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {departmentsList &&
              departmentsList?.map((ele, index) => {
                return (
                  <tr key={ele?.id}>
                    <td>{index + 1}.</td>
                    <td>{ele?.name}</td>
                    {/* <td>
                      <ToggleButton
                        value={ele?.status === 1 ? true : false}
                        onToggle={() => handleActiveToggle(ele?.id)}
                        activeLabel=""
                        inactiveLabel=""
                      />
                    </td> */}
                    {/* <td>
                      <div className="inner-actions">
                        <div className="edit-action"
                          onClick={() => {
                            setUpdateDptModal(true);
                            setUpdateDptName(ele?.name);
                            setUpdateDptId(ele?.id);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </div>

                        <div className="trash-action"
                          onClick={() => setDeleteModal(true)}
                        >
                          <i className="fas fa-trash"></i>
                        </div>
                      </div>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>

      {/* Department Delete Modal */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Department Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this department!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => setDeleteModal(false)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Department Modal */}
      <Modal
        show={addDptModal}
        onHide={() => {
          setAddDptModal(false);
          setError(false);
          setDptName("");
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Department Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the department name"
                value={dptName}
                onChange={(e) => setDptName(e.target.value)}
              />
              {error && !dptName && (
                <span className="text-danger">
                  Please enter the department name
                </span>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setAddDptModal(false);
              setError(false);
              setDptName("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleAddDpt()}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update Department Modal */}
      <Modal
        show={updateDptModal}
        onHide={() => {
          setUpdateDptModal(false);
          setError(false);
          setUpdateDptName("");
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Department Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the department name"
                value={updateDptName}
                onChange={(e) => setUpdateDptName(e.target.value)}
              />
              {error && !updateDptName && (
                <span className="text-danger">
                  Please enter the department name
                </span>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setUpdateDptModal(false);
              setError(false);
              setUpdateDptName("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleUpdateDpt()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default DepartmentsList;
