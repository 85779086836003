import { Fragment, useContext, useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import { Api } from "../../ApiData";
import { AppContext } from "../../context/AppContext";
import ProfileMenImg from "../../assets/images/profilemen.png";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditProject = () => {
    const navigate = useNavigate();
    const params=useParams();
    const [teamLeadsList, setTeamLeadsList] = useState([]);

    const [upworkIds, setUpworkIds] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const paymentType = ["Fixed", "Billing", "Inhouse","R&D"];
    const [editProject, setEditProject] = useState({
        id:"",
        project_name: "",
        client_name: "",
        fk_upwork_id: "",
        payment_type: "",
        client_handled_by: "",
        team_leader: "",
        start_date: new Date(),
        end_date: new Date(),
    })

    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(false);

    const getProjectById=async()=>{
        let project_id=params?.id;
        let apiRes= await Api?.getProjectById(project_id);
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            // setUpworkIds(apiRes?.body);

            let body = apiRes?.body[0];

            setEditProject({
                ...editProject,
                id:body?.id,
                project_name: body?.project_name,
                client_name: body?.client_name,
                fk_upwork_id: body?.fk_upwork_id,
                payment_type: body?.payment_type,
                client_handled_by: body?.client_handled_by,
                team_leader: body?.team_leader,
                start_date: new Date(body?.start_date),
                end_date: new Date(body?.end_date),
            })

            getAllUpworkIds();
            getActiveEmployeesList();
            getTeamLeadsData();

        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    }

    const getAllUpworkIds = async () => {
        const apiRes = await Api?.getActiveUpworkIds();
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setUpworkIds(apiRes?.body);
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    };

    const getActiveEmployeesList = async () => {
        const apiRes = await Api?.getActiveEmployeesList();
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setEmployeeList(apiRes?.body);
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    };

    const getTeamLeadsData = async () => {
        const apiRes = await Api?.getActiveTeamLeads();
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setTeamLeadsList(apiRes?.body);
            console.log("apiRes?.body", apiRes?.body);
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    };

    const handleSubmit = async () => {
        setError(true)
        if (!editProject.project_name || !editProject.client_name || !editProject.fk_upwork_id || !editProject.payment_type || !editProject.client_handled_by || !editProject.team_leader || !editProject.start_date || !editProject?.end_date) {
            return;
        } else {
            if(!compareDates(editProject.start_date, editProject.end_date)){
                toast.error("Start date must be equal to or earlier than the end date.")
                return ;
            }
            
            setError(false)
            setLoader(true);
            let apiRes = await Api?.editProject(editProject);
            setLoader(false);
            if (apiRes?.status >= 200 && apiRes?.status <= 399) {
                navigate("/project-report-list")
                toast.success(apiRes?.message)
            }
            if (apiRes?.status >= 400 && apiRes?.status <= 500) {
                toast.error(apiRes?.message);
                if (apiRes?.statusCode === 401) {
                    navigate("/");
                }
            }
        }
    };

    const compareDates = (date1, date2) => {
        const timestamp1 = new Date(date1).getTime();
        const timestamp2 = new Date(date2).getTime();
        console.log(timestamp1, timestamp2);
        if (timestamp1 > timestamp2 || timestamp1 == timestamp2) {
            return false;
        } else {
            return true;
        }
    }

    const changeUpworkId = (e) => {
        setEditProject({ ...editProject, fk_upwork_id: e.target.value })
    }

    const changePaymentType = (e) => {
        setEditProject({ ...editProject, payment_type: e.target.value })
    }

    const changeTeamLead = (e) => {
        setEditProject({ ...editProject, team_leader: e.target.value })
    }

    const changeEmpH = (e) => {
        setEditProject({ ...editProject, client_handled_by: e.target.value })
    }


    const handleKeyPress = (event) => {
        // Allow only alphabets and certain special characters
        const regex = /^[A-Za-z.,'"\s]+$/;
        const isValidInput = regex.test(event.key);

        if (!isValidInput) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        getProjectById();
    }, []);

    return (
        <Fragment>
            <div id="add-emp-details" className="right-nav-section">
                <div className="row">
                    <div className="col-12">
                        <div className="profile_shade">
                            <div className="grade_content profile_title">
                                <h2>Add New Project</h2>
                                <Link className="back-to" to="/project-report-list">Back</Link>
                            </div>
                            <hr className="m-0" />
                            <div className="row">
                                <div className="col-12">
                                    <div className="update_feild">
                                        <div className="row align-items-center">
                                            <div className="col-lg-12">
                                                <div className="checkout_field edit_field">
                                                    <div className="row">
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Project Name <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>
                                                                <div className="icon">
                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.42857"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleFormControlInput1"
                                                                    placeholder="Project Name"
                                                                    value={editProject?.project_name}
                                                                    onChange={(e) =>
                                                                        setEditProject({
                                                                            ...editProject,
                                                                            project_name: e.target.value,
                                                                        })
                                                                    }
                                                                //   onKeyPress={handleKeyPress}
                                                                />
                                                                {error && !editProject?.project_name && (
                                                                    <span className="text-danger">
                                                                        Please enter project name
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Client Name
                                                                </label>
                                                                <div className="icon">
                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.42857"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleFormControlInput1"
                                                                    placeholder="Client Name"
                                                                    value={editProject?.client_name}
                                                                    onChange={(e) =>
                                                                        setEditProject({
                                                                            ...editProject,
                                                                            client_name: e.target.value,
                                                                        })
                                                                    }
                                                                    onKeyPress={handleKeyPress}
                                                                />
                                                                {error && !editProject?.client_name && (
                                                                    <span className="text-danger">
                                                                        Please enter client name
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Select Upwork Id <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>
                                                                <select
                                                                    className="form-select no-space"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => changeUpworkId(e)}
                                                                    defaultValue="Select Upwork Id"
                                                                >
                                                                    <option disabled hidden>Select Upwork Id</option>
                                                                    {upworkIds &&
                                                                        upworkIds.map((u_id) => (
                                                                            <option key={u_id?.id} value={u_id?.id} selected={editProject?.fk_upwork_id==u_id?.id}>
                                                                                {u_id?.upwork_id}
                                                                            </option>
                                                                    ))}
                                                                </select>
                                                                {error && !editProject?.fk_upwork_id && (
                                                                    <span className="text-danger">
                                                                        Please select Upwork id
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Payment Type <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>
                                                                <select
                                                                    className="form-select no-space"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => changePaymentType(e)}
                                                                    defaultValue="Select Payment Type"
                                                                >
                                                                    <option disabled hidden>Select Payment Type</option>
                                                                    {paymentType &&
                                                                        paymentType.map((type) => (
                                                                            <option key={type} value={type} selected={editProject?.payment_type==type}>
                                                                                {type}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {error && !editProject?.payment_type && (
                                                                    <span className="text-danger">
                                                                        Please select payment type
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Select Employee (Handled By) <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>

                                                                <div className="icon">
                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.42857"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <select
                                                                    className="form-select"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => changeEmpH(e)}
                                                                    defaultValue="Select Employee"
                                                                >
                                                                    <option disabled hidden>Select Employee</option>
                                                                    {employeeList &&
                                                                        employeeList.map((ele) => (
                                                                            <option key={ele?.id} value={ele?.id} selected={editProject?.client_handled_by==ele?.id} >
                                                                                {ele?.first_name} {ele?.last_name}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {error && !editProject?.client_handled_by && (
                                                                    <span className="text-danger">
                                                                        Please select employee
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Team Lead <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>
                                                                <div className="icon">
                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.42857"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <select
                                                                    className="form-select"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => changeTeamLead(e)}
                                                                    defaultValue="Select team lead"
                                                                >
                                                                    <option disabled hidden>Select team lead</option>
                                                                    {teamLeadsList &&
                                                                        teamLeadsList.map((ele) => (
                                                                            <option key={ele?.id} value={ele?.id} selected={editProject?.team_leader==ele?.id}>
                                                                                {ele?.first_name + "" + ele?.last_name}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {error && !editProject?.team_leader && (
                                                                    <span className="text-danger">
                                                                        Please select team lead
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Start Date <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>

                                                                <DatePicker
                                                                    selected={editProject?.start_date}
                                                                    onChange={(date) => setEditProject({ ...editProject, start_date: date })}
                                                                    //   minDate={new Date()} // Disable past dates
                                                                    dateFormat="dd/MM/yyyy" // Specify the date format 
                                                                />

                                                                {error && !editProject?.start_date && (
                                                                    <span className="text-danger">
                                                                        Please select start date
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    End Date <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>

                                                                <DatePicker
                                                                    selected={editProject?.end_date}
                                                                    onChange={(date) => setEditProject({ ...editProject, end_date: date })}
                                                                    minDate={new Date()} // Disable past dates
                                                                    dateFormat="dd/MM/yyyy" // Specify the date format  
                                                                />

                                                                {error && !editProject?.end_date && (
                                                                    <span className="text-danger">
                                                                        Please select end date
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <Button
                                                        variant="success"
                                                        onClick={() => handleSubmit()}
                                                        disabled={loader}
                                                    >
                                                        {loader && <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />}
                                                        {" "}Update
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default EditProject;
