class Auth {
  authenticated = false;

  login(token) {
    localStorage.setItem("token", token);

    if (token) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
  }

  storeAdminData(data){
    localStorage.setItem("data", JSON.stringify(data));
  }

  getAdminData(){
    const data = localStorage.getItem("data");
    return JSON.parse(data);
  }

  // storeEmail(email) {
  //   localStorage.setItem(
  //     "email",
  //     email
  //       .split("")
  //       .map((char) => `&#${char.charCodeAt(0)};`)
  //       .join("")
  //   );
  // }

  // getEmail() {
  //   const email = localStorage.getItem("email");
  //   return email
  //     .split(";")
  //     .filter((entity) => entity)
  //     .map((entity) => String.fromCharCode(entity.slice(2)))
  //     .join("");
  // }

  // removeEmail(){
  //   localStorage.removeItem("email");
  // }

  token() {
    const token = localStorage.getItem("token");
    return token;
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("data");
    this.authenticated = false;
  }

  isUserAuthenticated() {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  }
}
// eslint-disable-next-line
export default new Auth();
